<div class="card w-100 login-card">
    <div class="card-body">
        <div class="logo">
            <img
                [src]="logoService.loginLogo"
                [alt]="
                    'GENERIC.LABELS.COMPANY_LOGO'
                        | translate: { companyName: companyName() }
                "
            />
        </div>
        <h1
            class="title"
            translate
        >
            LOGIN.LOGIN_TITLE
        </h1>

        <div class="px-md-5">
            <p
                class="required-disclaimer cdk-visually-hidden"
                translate
            >
                DYNAMIC_FORM.MANDATORY_FIELD
            </p>
            <div
                class="mt-4"
                *ngIf="showAzureLogin()"
            >
                <dd-microsoft-login-button
                    [openIdConfig]="company().openIdConfiguration"
                ></dd-microsoft-login-button>
                <hr
                    *ngIf="!isSso"
                    class="login-spacer"
                />
            </div>
            <form
                [formGroup]="loginForm"
                (ngSubmit)="login()"
                *ngIf="!isSso"
            >
                <mrc-form-item-field
                    [label]="'USER.EMAIL' | translate"
                    [control]="loginForm.controls.email"
                    [id]="'email'"
                >
                    <input
                        id="email"
                        type="email"
                        name="email"
                        class="form-control"
                        formControlName="email"
                        data-testid="email"
                        [placeholder]="'USER.EMAIL' | translate"
                    />
                </mrc-form-item-field>
                <mrc-form-item-field
                    [label]="'LOGIN.PASSWORD' | translate"
                    [control]="loginForm.controls.password"
                    [id]="'password'"
                >
                    <input
                        id="password"
                        type="password"
                        name="password"
                        class="form-control"
                        formControlName="password"
                        data-testid="password"
                        [placeholder]="'LOGIN.PASSWORD' | translate"
                    />
                </mrc-form-item-field>
                <button
                    type="submit"
                    class="btn btn-primary w-100"
                    data-testid="login"
                    [disabled]="loginForm.disabled"
                >
                    <span translate>LOGIN.LOGIN</span>
                </button>
                <div class="mt-3">
                    <a
                        [routerLink]="'../password/forgot-password'"
                        class="d-flex justify-content-end link-inherit"
                        translate
                        >LOGIN.FORGOT_PASSWORD</a
                    >
                </div>
            </form>
            <p
                aria-hidden="true"
                class="mb-0 mt-3"
                *ngIf="!isSso"
                translate
            >
                DYNAMIC_FORM.MANDATORY_FIELD
            </p>
        </div>
    </div>
</div>
