<mrc-form-item-field
    [control]="control"
    [id]="field.key"
    [label]="field.label | translate"
>
    <ng-container [ngSwitch]="field.type">
        <input
            *ngSwitchCase="'text'"
            [id]="field.key"
            [formControl]="control"
            [placeholder]="field.placeholder || ''"
            class="form-control"
            type="text"
        />
        <input
            *ngSwitchCase="'number'"
            [id]="field.key"
            [formControl]="control"
            [placeholder]="field.placeholder || ''"
            class="form-control"
            type="text"
        />
        <ng-select
            *ngSwitchCase="'select'"
            [labelForId]="field.key"
            class="form-control"
            [formControl]="control"
            [items]="field.options"
            bindLabel="label"
            bindValue="value"
            [clearable]="true"
            [searchable]="false"
        ></ng-select>
    </ng-container>
</mrc-form-item-field>
