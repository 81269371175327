export function toSnakeCase(value: string): string {
    return value?.length
        ? value
              .replace(
                  /(([a-z])(?=[A-Z][a-zA-Z])|([A-Z])(?=[A-Z][a-z]))/g,
                  '$1_',
              )
              .toLowerCase()
        : value;
}
