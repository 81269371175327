import { FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function nameInEmailValidator(domainName: string): ValidatorFn {
    return (control: FormControl<string>): ValidationErrors | null => {
        if (!control?.parent?.controls || !control.value) return null;

        const firstName = control.parent?.controls?.['firstName'].value;
        const lastName = control.parent?.controls?.['lastName'].value;
        const splittedMail = control.value.split('@');
        const namePart = splittedMail[0].split('.');
        const emailFirstName = namePart?.[0];
        const preEmailLastName = namePart?.[1];
        const emailLastName = preEmailLastName
            ?.split('')
            .map((x) => x.replace(/[0-9]/g, ''))
            .join('');
        const emailDomain = splittedMail[1];
        const firstNameValid =
            emailFirstName &&
            firstName?.localeCompare(emailFirstName, undefined, {
                sensitivity: 'base',
            }) === 0;
        const lastNameValid =
            emailLastName &&
            lastName?.localeCompare(emailLastName, undefined, {
                sensitivity: 'base',
            }) === 0;
        const domainValid =
            domainName && emailDomain?.localeCompare(domainName) === 0;
        return firstNameValid && lastNameValid && domainValid
            ? null
            : { addressIncorrect: 'addressIncorrect' };
    };
}
