import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TokenStorageService {
    constructor(private cookie: CookieService) {}

    public getAccessToken(tokenKey: string): Observable<string> {
        return of(this.cookie.get(tokenKey));
    }

    public getAccessTokenSync(tokenKey: string): string {
        return this.cookie.get(tokenKey);
    }

    public getAccessTokenExp(tokenKey: string): Observable<string> {
        return of(this.cookie.get(`${tokenKey}_exp`));
    }

    public getAccessTokenExpTimestamp(tokenKey: string): number {
        return parseInt(this.cookie.get(`${tokenKey}_exp_timestamp`));
    }

    public checkAccessTokenExpiration(tokenKey: string): boolean {
        return this.cookie.check(`${tokenKey}_exp`);
    }

    public checkAccessToken(tokenKey: string): boolean {
        return this.cookie.check(tokenKey);
    }

    public setAccessToken(token: string, tokenKey: string): void {
        this.cookie.set(`${tokenKey}`, token, 0, '/', null, true, 'Lax');
    }

    public setAccessTokenExpiration(
        tokenExpirationInSeconds: string,
        tokenKey: string,
    ): void {
        const expirationMiliseconds =
            Date.now() + parseInt(tokenExpirationInSeconds, 10) * 1000;
        this.cookie.set(
            `${tokenKey}_exp`,
            tokenExpirationInSeconds,
            new Date(expirationMiliseconds),
            '/',
            null,
            true,
            'Lax',
        );
    }

    public setAccessTokenExpiryTimestamp(
        expiryTimestamp: number,
        tokenKey: string,
    ): void {
        this.cookie.set(
            `${tokenKey}_exp_timestamp`,
            expiryTimestamp.toString(),
            0,
            '/',
            null,
            true,
            'Lax',
        );
    }

    public clear(tokenKey: string): void {
        this.cookie.delete(tokenKey, '/');
        this.cookie.delete(`${tokenKey}_exp`, '/');
    }

    public clearAll(): void {
        this.cookie.deleteAll('/');
    }

    /**
     * This replaces the clearAll function in all but the error case
     * to make sure that persistent cookies aren't deleted on login/logout
     */
    public clearNonPersistent(): void {
        const cookies = this.cookie.getAll();

        for (const cookie in cookies) {
            // suffix cookies with '_Persistent' to keep them
            if (!cookie.endsWith('_Persistent')) {
                this.cookie.delete(cookie, '/');
            }
        }
    }
}
