import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '@shared/shared.module';
import { AddFieldModalComponent } from './components/add-field-modal/add-field-modal.component';
import { AdditionalFieldComponent } from './components/additional-field/additional-field.component';

@NgModule({
    declarations: [AddFieldModalComponent, AdditionalFieldComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        NgSelectModule,
        MatCheckboxModule,
        TranslateModule,
        MatInputModule,
        MatDialogModule,
        SharedModule,
    ],
    exports: [AdditionalFieldComponent],
})
export class AdditionalFieldsModule {}
