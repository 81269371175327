<main class="main-content">
    <div class="row">
        <div class="col-lg-12">
            <div class="text-end">
                <h3 *ngIf="timeLeft < 0; else showTimer">
                    {{ 'GENERIC.TOKEN_EXPIRED' | translate }}
                </h3>
                <ng-template #showTimer
                    >{{ timeLeft * 1000 | date: 'mm:ss' }}
                </ng-template>
            </div>
        </div>
    </div>

    <form
        #form
        (ngSubmit)="submit()"
        [formGroup]="externalRegistrationForm"
    >
        <div class="row">
            <div class="col-lg-12">
                <div class="card-body mt-4">
                    <h1>{{ 'GENERIC.REGISTRATION' | translate }}</h1>
                    <mrc-form-item-field
                        [id]="'first_name'"
                        [label]="'EXTERNAL_JWT.FIRST_NAME' | translate"
                        [control]="externalRegistrationForm.controls.firstName"
                    >
                        <input
                            class="form-control form-control-bg"
                            formControlName="firstName"
                            id="first_name"
                            name="first_name"
                            [placeholder]="
                                'EXTERNAL_JWT.FIRST_NAME' | translate
                            "
                        />
                    </mrc-form-item-field>
                    <mrc-form-item-field
                        [id]="'last_name'"
                        [label]="'EXTERNAL_JWT.LAST_NAME' | translate"
                        [control]="externalRegistrationForm.controls.lastName"
                    >
                        <input
                            class="form-control form-control-bg"
                            formControlName="lastName"
                            id="last_name"
                            name="last_name"
                            [placeholder]="'EXTERNAL_JWT.LAST_NAME' | translate"
                        />
                    </mrc-form-item-field>
                    <mrc-form-item-field
                        [id]="'email'"
                        [label]="'EXTERNAL_JWT.EMAIL' | translate"
                        [control]="externalRegistrationForm.controls.email"
                    >
                        <input
                            class="form-control form-control-bg"
                            formControlName="email"
                            id="email"
                            name="email"
                            [placeholder]="'EXTERNAL_JWT.EMAIL' | translate"
                        />
                    </mrc-form-item-field>

                    <div class="text-center">
                        <small *ngIf="childCompanies?.length > 1">{{
                            (isCivilServant()
                                ? 'EXTERNAL.REGISTRATION.CHOOSE_EMPLOYER'
                                : 'EXTERNAL.REGISTRATION.CHOOSE_COMPANY'
                            ) | translate
                        }}</small>
                    </div>
                    <mrc-form-item-field
                        *ngIf="childCompanies?.length > 1"
                        [control]="
                            externalRegistrationForm.controls?.selectedCompany
                        "
                        [label]="
                            ((isCivilServant()
                                ? 'EXTERNAL_JWT.EMPLOYED_WITH'
                                : 'EXTERNAL_JWT.WITH_COMPANY'
                            ) | translate) + ':'
                        "
                        [id]="'selectedCompany'"
                    >
                        <ng-select
                            labelForId="selectedCompany"
                            [clearable]="false"
                            [items]="childCompanies"
                            [searchable]="false"
                            bindLabel="name"
                            name="selectedCompany"
                            class="form-control"
                            formControlName="selectedCompany"
                            [notFoundText]="
                                (isCivilServant()
                                    ? 'EXTERNAL_JWT.NO_EMPLOYER_FOUND'
                                    : 'EXTERNAL_JWT.NO_COMPANY_FOUND'
                                ) | translate
                            "
                            [placeholder]="
                                (isCivilServant()
                                    ? 'EXTERNAL_JWT.PLEASE_SELECT_YOUR_EMPLOYEE'
                                    : 'EXTERNAL_JWT.PLEASE_SELECT_YOUR_COMPANY'
                                ) | translate
                            "
                        ></ng-select>
                    </mrc-form-item-field>

                    <div
                        class="row"
                        *ngFor="let field of company?.additional_fields"
                    >
                        <div class="col">
                            <dd-additional-field
                                [field]="field"
                                [control]="
                                    externalRegistrationForm.controls[field.key]
                                "
                            ></dd-additional-field>
                        </div>
                    </div>

                    <dd-select-beamten-status
                        *ngIf="isCivilServant()"
                        [beamtenControl]="calculator"
                        [publicAuthority]="true"
                        [userIsCivilServant]="true"
                    ></dd-select-beamten-status>

                    <div class="row">
                        <div class="col">
                            <button
                                type="button"
                                [attr.aria-label]="
                                    'REGISTRATION.SHOW_PRIVACY_POLICY'
                                        | translate
                                "
                                (click)="openPrivacyPolicyDialog()"
                                class="btn btn-link p-0"
                                translate
                            >
                                REGISTRATION.PRIVACY_POLICY
                            </button>
                        </div>
                    </div>

                    <!-- Workaround for ng-select not working with action form submit which is necessary for the redirect-->
                    <mrc-form-item-field hidden="">
                        <input
                            [value]="
                                externalRegistrationForm.controls
                                    ?.selectedCompany?.value
                            "
                            name="company_id"
                            id="company_id"
                        />
                    </mrc-form-item-field>
                    <mrc-form-item-field hidden="">
                        <input
                            [(ngModel)]="externalToken"
                            class="form-control form-control-bg"
                            formControlName="token"
                            id="token"
                            name="token"
                        />
                    </mrc-form-item-field>
                    <mrc-form-item-field hidden="">
                        <input
                            [(ngModel)]="externalUserId"
                            class="form-control form-control-bg"
                            formControlName="externalUserId"
                            id="externalUserId"
                            name="externalUserId"
                        />
                    </mrc-form-item-field>
                    <button
                        [disabled]="
                            externalRegistrationForm.invalid || isSubmitClicked
                        "
                        class="btn btn-primary"
                        type="submit"
                        value="Submit"
                    >
                        {{ 'GENERIC.ACTIONS.SEND' | translate }}
                    </button>
                </div>
            </div>
            <ng-template #policyModal>
                <div class="modal-close">
                    <button
                        (click)="modalRef.hide()"
                        [attr.aria-label]="'GENERIC.ACTIONS.CLOSE' | translate"
                        class="btn-close"
                        type="button"
                    ></button>
                </div>
                <div class="modal-body">
                    <div class="text-center">
                        <div class="text-holder">
                            <h2
                                class="title"
                                translate
                            >
                                REGISTRATION.DATA_PROTECTION
                            </h2>
                        </div>
                        <div [innerHTML]="policy"></div>

                        <button
                            type="button"
                            (click)="modalRef.hide()"
                            class="btn btn-primary"
                            translate
                        >
                            GENERIC.ACTIONS.CLOSE
                        </button>
                    </div>
                </div>
            </ng-template>
        </div>
    </form>
</main>
<dd-public-footer></dd-public-footer>
